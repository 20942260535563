.loginPage {
  background-color: white;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
}
.loginPage .container {
  display: flex;
  flex-direction: column;
  max-width: 370px;
  justify-content: center;
  width: 100%;
  /* Error message styles */
}
.loginPage .container figure {
  height: 82px;
  margin: 0 auto 20% auto;
}
.loginPage .container figure img {
  width: 100%;
  height: 125%;
  -o-object-fit: contain;
     object-fit: contain;
}
.loginPage .container .remember-container {
  display: flex;
  justify-content: flex-start;
  margin: 19px 0px 31px 0px;
}
.loginPage .container .remember-container .remember {
  display: flex;
  align-items: center;
  color: black;
}
.loginPage .container .remember-container .remember .custom-checkbox .checkmark {
  width: 28px;
  height: 28px;
  border: 1px solid white;
}
.loginPage .container .remember-container .remember .custom-checkbox .checkmark::before {
  left: 12%;
}
.loginPage .container .remember-container .remember .custom-checkbox p {
  color: #fff;
  font-family: Inter Tight;
  font-size: 16px;
  margin: 0px 0px 0px 10px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}
.loginPage .container .remember-container .remember p {
  margin: 0px;
}
.loginPage .container .remember-container .remember input {
  margin-right: 10px;
  width: 23px;
  height: 23px;
  outline: none;
  border: none;
}
.loginPage .container .remember-container .forgot {
  color: #fff;
  font-family: "Inter Tight";
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  text-decoration-line: underline;
  margin: 4px;
}
.loginPage .container .error-message {
  color: red;
  font-size: 14px;
  font-weight: 500;
  margin-top: 5px;
  margin-bottom: 5px;
}
.loginPage .container a {
  display: flex;
  text-decoration: none;
}
.loginPage .container a button {
  border: none;
  outline: none;
  width: 100%;
  border-radius: 10px;
  background: #006b8c;
  box-shadow: 0px -1px 6px 0px rgba(0, 0, 0, 0.08) inset;
  padding: 23px;
  color: #fff;
  font-family: "Inter Tight";
  font-size: 16px;
  margin-top: 10px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}/*# sourceMappingURL=Login.css.map */