.input-login-container {
  position: relative;
  width: 362px;
}
.input-login-container .input-icon {
  position: absolute;
  top: 60%;
  transform: translateY(-50%);
  left: 24px;
  height: 66px;
  margin: 0;
  display: flex;
  align-items: center;
}
.input-login-container .login-input {
  padding: 23px 0px 22px 56px;
  border-radius: 10px;
  width: 305px;
  box-shadow: 0px -1px 6px 0px rgba(0, 0, 0, 0.0784313725) inset;
  border: 1px solid #e5e5e5;
  margin-top: 14px;
  color: black;
  font-family: "Inter Tight";
  font-size: 16px;
  outline: none;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

/* SearchInput.scss *//*# sourceMappingURL=Input.css.map */