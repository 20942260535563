.selling-container {
  display: flex;
  flex-direction: column;
  width: calc(100% - 140px);
  margin: 0 auto;
}
.selling-container .selling-row {
  display: flex;
  gap: 10px;
  margin: 20px 0px;
}
.selling-container .selling-row .search-button {
  padding: 12px 27px;
  border-radius: 10px;
  background: #006b8c;
  font-size: 16px;
  border: none;
  outline: none;
  box-shadow: 0px -1px 6px 0px rgba(0, 0, 0, 0.08) inset;
  color: white;
  margin: 4px;
}
.selling-container .selling-row .search-input {
  display: flex;
  align-items: center;
  border-radius: 10px;
  position: relative;
  flex: 1;
}
.selling-container .selling-row .search-input .input-field {
  flex: 1 1;
  background: #f5f5f5;
  box-shadow: 0px -1px 6px 0px rgba(0, 0, 0, 0.08) inset;
  padding: 23px 23px 23px 55px;
  border: none;
  font-size: 16px;
  border-radius: 10px;
  outline: none;
  color: #444;
  font-family: Inter Tight;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}
.selling-container .selling-row .search-input .search-icon {
  color: #777;
  margin-right: 5px;
  margin-left: 10px;
  position: absolute;
  font-size: 16px;
  top: 24px;
  font-weight: 100;
  left: 20px;
}
.selling-container .row {
  display: flex;
  gap: 16px;
  margin: 20px 0px;
}
.selling-container .row .number-box {
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 7px;
  background: #F5F5F5;
  flex-direction: column;
  width: 277px;
  padding: 52px 0px 61px 0px;
}
.selling-container .row .number-box h2 {
  text-align: center;
  font-family: Inter Tight;
  font-size: 48px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  margin: 0px 0px 0px 0px;
}
.selling-container .row .number-box p {
  text-align: center;
  font-family: Inter Tight;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}
.selling-container .color {
  color: #ff5d36;
  text-align: center;
  font-family: Inter Tight;
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
}
.selling-container .selling-listing-table {
  border-bottom: 1px solid #f0f0f0;
  margin: 0px 0px 100px 0px;
}
.selling-container .selling-listing-table table.blueTable {
  width: 100%;
  text-align: center;
  border-collapse: collapse;
  border-radius: 10px 10px 0 0;
  font-family: "Inter Tight", sans-serif;
  overflow: hidden;
}
.selling-container .selling-listing-table table.blueTable td,
.selling-container .selling-listing-table table.blueTable th {
  padding: 25px 21px 25px 21px;
}
.selling-container .selling-listing-table table.blueTable tbody td {
  font-size: 16px;
}
.selling-container .selling-listing-table table.blueTable thead {
  background: #ffcfc3;
}
.selling-container .selling-listing-table table.blueTable thead th {
  font-size: 16px;
  font-weight: normal;
  color: #000000;
  text-align: center;
}
.selling-container .selling-listing-table table.blueTable tbody tr:nth-child(even) {
  background-color: #f5f5f5; /* Change this to your desired background color */
}
.selling-container .selling-listing-table table.blueTable tbody tr:last-child td {
  border-bottom: none; /* Remove the border on the last row */
}/*# sourceMappingURL=Selling.css.map */