body {
  margin: 0 auto;
  padding: 0;
  box-sizing: border-box;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-family: 'Inter Tight', sans-serif;  
}
.submit{
  cursor: pointer;
}
.save{
  cursor: pointer;
}
.custom-checkbox input:checked + .checkmark{
  cursor: pointer;
}
.checkmark{
  cursor: pointer;
}
.search-button{
  cursor: pointer;
}
button{
  cursor: pointer;
}