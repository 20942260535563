.header {
  display: flex;
  width: 100%;
  flex-direction: column;
  margin: 0 auto;
  justify-content: center;
  align-items: center;
  border-bottom: 1px solid rgba(192, 192, 192, 0.27);
}
.header .upper {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 2% auto 0px auto;
  width: calc(100% - 140px);
}
.header .upper img {
  height: 55px;
  width: 135px;
  -o-object-fit: contain;
     object-fit: contain;
}
.header .upper h3 {
  font-size: 32px;
  margin: 0px;
}
.header .upper .nav-container {
  display: flex;
  gap: 40px;
  align-items: center;
  margin: 0px;
  align-items: center;
  justify-content: space-around;
  margin: 0px 0px 0px 30px;
  font-weight: 500;
}
.header .upper .nav-container .button-nav {
  padding: 0px;
}
.header .upper .nav-container .button-nav .button-icon {
  width: 25px;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 25px;
}
.header .upper .shop-box {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 10px;
}
.header .upper .shop-box h3 {
  font-size: 16px;
  margin: 0px;
}
.header .upper .shop-box .circle {
  background-color: #f0f0f0;
  width: 37px;
  border-radius: 222px;
  height: 37px;
}
.header .upper .button .button-icon {
  margin: 4px 0px 0px 0px;
}
.header .lower-nav {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: calc(100% - 140px);
  margin: 45px auto 26px auto;
  flex-direction: row-reverse;
}
.header .lower-nav .tags-container {
  display: flex;
  gap: 13px;
  align-items: center;
}
.header .lower-nav .tags-container button p span svg {
  height: 18px;
}
.header .lower-nav h4 {
  margin: 0px;
}
.header .lower-nav .time {
  color: #444;
  font-family: "Inter Tight";
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}
.header .lower {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: calc(100% - 140px);
  margin: 19px auto 10px auto;
}
.header .lower .tags-container {
  display: flex;
  gap: 13px;
}
.header .lower .time {
  color: #444;
  font-family: "Inter Tight";
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  align-items: center;
  display: flex;
  gap: 10px;
  line-height: normal;
}
.header .lower .time span svg {
  height: 18px;
}

.button-nav {
  padding: 11px 20px;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.3s, color 0.3s;
}

@media screen and (max-width: 1024px) {
  .header .upper {
    margin: 25px auto 0px auto;
    width: calc(100% - 90px);
  }
  .header .upper h3 {
    font-size: 28px;
  }
  .header .lower {
    margin: 20px auto 16px auto;
    width: calc(100% - 90px);
  }
  .header .lower .time {
    font-size: 13px;
  }
}
.active-nav {
  padding: 11px 20px !important;
}/*# sourceMappingURL=Header.css.map */