/* Modal Overlay Styles */
.modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.24);
  /* Semi-transparent background */
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
  /* Ensure the modal is above other elements */
}

/* Modal Styles */
/* Close Button Styles */
.close-button {
  position: absolute;
  top: 10px;
  right: 10px;
  background: none;
  border: none;
  font-size: 24px;
  cursor: pointer;
}

.modal {
  display: flex;
  flex-direction: column;
  padding: 0px 25px;
  width: 50%;
  background-color: #fff;
  max-width: 600px;
  border-radius: 8px;
}

.modal .Upper-modal-row {
  display: flex;
  justify-content: space-between;
  border-bottom: 1px solid #f5f5f5;
  align-items: center;
}

.modal .Upper-modal-row h2 {
  color: #000;
  font-family: Inter Tight;
  margin: 0px;
  font-size: 20px;
  margin-top: 35px;
  margin-bottom: 32px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}

.modal .rown {
  display: flex;
  justify-content: space-between;
  gap: 15px;
  margin: 20px 0px 20px 0px;
}

.modal .rown .button-mediur {
  border: none;
  outline: none;
  padding: 22px 29px;
  border-radius: 10px;
  width: 280px;
  background: #006b8c;
  box-shadow: 0px -1px 6px 0px rgba(0, 0, 0, 0.08) inset;
}

.modal .rown .button-mediur .button-text {
  display: flex;
  gap: 12px;
  align-items: center;
  font-family: "Inter Tight";
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  margin: 0px;
}

.modal .input-boxer {
  display: flex;
  position: relative;
}

.modal .input-boxer .youu {
  border-radius: 10px;
  display: flex;
  padding: 23px;
  width: 274px;
  color: #444;
  gap: 11px;
  font-family: Inter Tight;
  border: none;
  outline: none;
  color: #1e1e1e;
  font-family: Inter Tight;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  background: #f5f5f5;
  box-shadow: 0px -1px 6px 0px rgba(0, 0, 0, 0.08) inset;
}

.modal .input-boxer .custom-select .options {
  width: 318px;
}

.modal .input-boxer .custom-select .options li span {
  position: relative;
  top: 1px;
  right: 6px;
}

.modal .input-boxer .custom-select .csi {
  border-radius: 10px;
  padding: 23px;
  width: 274px;
  color: #444;
  font-family: Inter Tight;
  border: none;
  outline: none;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  background: #f5f5f5;
  box-shadow: 0px -1px 6px 0px rgba(0, 0, 0, 0.08) inset;
}

.modal .input-boxer input,
.modal .input-boxer select {
  border-radius: 10px;
  padding: 23px;
  width: 100%;
  color: #444;
  font-family: Inter Tight;
  border: none;
  outline: none;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  background: #f5f5f5;
  box-shadow: 0px -1px 6px 0px rgba(0, 0, 0, 0.08) inset;
}

.modal .input-boxer span {
  position: absolute;
  top: 23px;
  right: 20px;
}

.row {
  display: flex;
  justify-content: space-between;
  margin: 50px 0px 0px 0px;
}

.row .button-mediur {
  border: none;
  outline: none;
  padding: 22px 29px;
  border-radius: 10px;
  background: #006b8c;
  box-shadow: 0px -1px 6px 0px rgba(0, 0, 0, 0.08) inset;
}

.row .button-mediur .button-text {
  display: flex;
  gap: 12px;
  align-items: center;
  font-family: "Inter Tight";
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  margin: 0px;
}

.row .button-mediur .button-text .button-icon {
  margin: 0px;
}

.row .input-boxer {
  display: flex;
  width: 49%;
  position: relative;
}

.row .input-boxer input,
.row .input-boxer select {
  border-radius: 10px;
  padding: 23px;
  width: 100%;
  color: #444;
  font-family: Inter Tight;
  border: none;
  outline: none;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  background: #f5f5f5;
  box-shadow: 0px -1px 6px 0px rgba(0, 0, 0, 0.08) inset;
}

.row .input-boxer span {
  position: absolute;
  top: 23px;
  right: 20px;
}

.columner {
  display: flex;
  flex-direction: column;
}

.columner .rows {
  display: flex;
  gap: 52px;
  margin-bottom: 44px;
}

.columner .rows .checkbox-container {
  font-size: 16px;
  display: flex;
  gap: 14px;
  align-items: center;
  /* Define the container for the checkbox */
  /* Hide the default checkbox input */
  /* Style the custom checkbox appearance */
  /* Style the custom checkbox when it's checked */
  /* Style the custom checkbox when it's hovered */
}

.columner .rows .checkbox-container .rounded-checkbox {
  display: inline-block;
  position: relative;
  width: 24px;
  /* Adjust the width and height to control the size of the checkbox */
  height: 24px;
}

.columner .rows .checkbox-container .rounded-checkbox input {
  display: none;
}

.columner .rows .checkbox-container .rounded-checkbox .checkmark {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: #d9d9d9;
  /* Set the background color to transparent */
  border: 2px solid #fff;
  /* Add a border to create the circle */
  border-radius: 50%;
  /* Make it round */
}

.columner .rows .checkbox-container .rounded-checkbox input:checked+.checkmark {
  background-color: #d9d9d9;
  width: 12px;
  height: 12px;
  border: 6px solid #ff7c5c;
  /* Change the background color to your desired color when checked */
}

.columner .rows .checkbox-container .rounded-checkbox:hover .checkmark {
  border-color: #888;
  /* Change the border color when hovered */
}

.columner .rows .checkbox-container input {
  border-radius: 50%;
}

.columner textarea {
  display: flex;
  min-height: 101px;
  border-radius: 10px;
  border: 1px solid rgba(68, 68, 68, 0.4);
  box-shadow: 0px -1px 6px 0px rgba(0, 0, 0, 0.08) inset;
  color: #444;
  font-family: Inter Tight;
  font-size: 16px;
  font-style: normal;
  padding: 23px;
  font-weight: 400;
  line-height: normal;
  margin-bottom: 37px;
}

.columner .modal-button {
  margin: 75px auto 47px auto;
}

.columner button {
  display: inline-flex;
  padding: 18px 68px 18px 61px;
  justify-content: center;
  align-items: center;
  gap: 12px;
  color: white;
  border-radius: 6px;
  color: #fff;
  font-family: Inter Tight;
  width: -moz-fit-content;
  width: fit-content;
  border: none;
  outline: none;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  align-self: center;
  line-height: normal;
  background: #ff7c5c;
}

.columner button .button-text {
  display: flex;
  gap: 12px;
  align-items: center;
  font-family: "Inter Tight";
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  margin: 0px;
}

.columner button .button-text .button-icon {
  margin: 0px;
}

.columneri {
  display: flex;
  flex-direction: column;
}

.columneri .rows {
  display: flex;
  gap: 52px;
  margin-bottom: 44px;
}

.columneri .rows .custom-checkbox .checkmark {
  background-color: #e3e3e3;
}

.columneri .rows .custom-checkbox input:checked+.checkmark {
  background-color: #ff7c5c;
}

.columneri textarea {
  display: flex;
  min-height: 101px;
  border-radius: 10px;
  border: 1px solid rgba(68, 68, 68, 0.4);
  box-shadow: 0px -1px 6px 0px rgba(0, 0, 0, 0.08) inset;
  color: #444;
  font-family: Inter Tight;
  font-size: 16px;
  font-style: normal;
  padding: 23px;
  font-weight: 400;
  line-height: normal;
}

.columneri .modal-button {
  margin: 37px auto 31px auto;
}

.columneri button {
  display: inline-flex;
  padding: 18px 68px 18px 61px;
  justify-content: center;
  align-items: center;
  gap: 12px;
  color: white;
  border-radius: 6px;
  color: #fff;
  font-family: Inter Tight;
  width: -moz-fit-content;
  width: fit-content;
  border: none;
  outline: none;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  align-self: center;
  line-height: normal;
  background: #ff7c5c;
}

.columneri button .button-text {
  display: flex;
  gap: 12px;
  align-items: center;
  font-family: "Inter Tight";
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  margin: 0px;
}

.columneri button .button-text .button-icon {
  margin: 0px;
}

.row-modal-eir .custom-select .options {
  height: 179px;
}

.row-modal-eir .custom-select .csi {
  width: 90%;
}

.reciept-container {
  display: flex;
  justify-content: center;
  width: 100%;
  max-height: 80vh;
  overflow-y: scroll;
  align-items: center;
}

.reciept-container .reciept {
  display: flex;
  width: 350px;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: 12px;
}

.reciept-container .reciept h1 {
  font-size: 34px;
  margin-top: 0px;
}

.reciept-container .reciept h2 {
  display: flex;
  align-items: center;
  font-size: 24px;
  gap: 20px;
  margin: 10px;
}

.reciept-container .reciept p {
  margin-top: 0;
  margin-bottom: 3px;
}

.reciept-container .reciept .reciept-row {
  display: flex;
  margin-bottom: 9px;
  justify-content: space-between;
  width: 100%;
  max-width: 350px;
}

.reciept-container .reciept .reciept-row p {
  width: 40%;
}

.reciept-container .reciept .reciept-row p {
  margin: 0;
}

.reciept-container .reciept .reow-reciept {
  display: flex;
  width: 100%;
  margin-bottom: 4px;
  max-width: 300px;
  margin: 0px 0px 10px 0px;
}

.reciept-container .reciept .reow-reciept .quantity {
  width: 80px;
  text-align: left;
  margin-bottom: 0;
  margin-right: 10px;
  margin-top: 0;
}

.reciept-container .reciept .reow-reciept .product {
  width: 85px;
  margin-bottom: 0;
  margin-top: 0;
}

.reciept-container .reciept .reow-reciept .price {
  width: 90px;
  margin-bottom: 0;
  margin-top: 0;
}

.reciept-container .reciept .reow-reciept p {
  margin: 0;
}

.reciept-container .reciept .reow-recieptsss {
  display: flex;
  justify-content: flex-end;
  gap: 30px;
  width: 60%;
  margin-bottom: 4px;
}

.reciept-container .reciept .reow-recieptsss p {
  margin: 0;
}

@media print {
  .receipt {
    display: block;
    width: auto;
    padding: 0;
    margin: 0;
  }

  .receipt .reciept h1 {
    font-size: 34px;
    margin-top: 0px;
  }

  .receipt h2 {
    display: flex;
    align-items: center;
    font-size: 24px;
    gap: 20px;
    margin: 10px;
  }

  .receipt p {
    margin-top: 0;
    margin-bottom: 3px;
  }

  .receipt .reciept-row {
    display: flex;
    margin-bottom: 9px;
    justify-content: space-between;
    width: auto;
  }

  .receipt .reciept-row p {
    width: 33%;
  }

  .receipt .reciept-row p {
    margin: 0;
  }

  .receipt .reow-reciept {
    display: flex;
    width: auto;
    margin-bottom: 4px;
    margin: 0px 0px 10px 0px;
  }

  .receipt .reow-reciept .quantity {
    width: 115px;
    text-align: left;
    margin-bottom: 0;
    margin-right: 10px;
    margin-top: 0;
  }

  .receipt .reow-reciept .product {
    width: 135px;
    margin-bottom: 0;
    margin-top: 0;
  }

  .receipt .reow-reciept .price {
    width: 90px;
    margin-bottom: 0;
    margin-top: 0;
  }

  .receipt .reow-reciept p {
    margin: 0;
  }

  .receipt .reow-recieptsss {
    display: flex;
    justify-content: flex-end;
    gap: 30px;
    width: 60%;
    margin-bottom: 4px;
  }

  .receipt .reow-recieptsss p {
    margin: 0;
  }
}

/*# sourceMappingURL=Modal.css.map */