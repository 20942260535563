.custom-select {
  position: relative;
  display: inline-block;
}

.custom-select .opt-button {
  /* border: 1px solid #ccc; */
  padding: 20px 20px;
  cursor: pointer;
  display: flex;
  gap: 6px;
  align-items: center;
  width: 100%;
  text-align: left;
  border-radius: 10px;
  background: #ffffff;
  box-shadow: 0px -1px 6px 0px rgba(0, 0, 0, 0.08) inset;
}

.custom-select .opt-button p {
  margin: 0px 0px 0px 7px;
  width: -moz-max-content;
  width: max-content;
}

.custom-select .opt-buttons {
  border: 1px solid #ccc;
  padding: 20px 20px;
  cursor: pointer;
  display: flex;
  justify-content: space-between;
  width: 200px;
  gap: 6px;
  align-items: center;
  text-align: left;
  border-radius: 10px;
  background: #f5f5f5;
  box-shadow: 0px -1px 6px 0px rgba(0, 0, 0, 0.08) inset;
}

.custom-select .opt-buttons p {
  margin: 0px 0px 0px 7px;
}

.custom-select .options {
  position: absolute;
  list-style-type: none;
  border-radius: 10px;
  width: 100%;
  background: #f5f5f5;
  margin: 0;
  padding: 0;
  border: 1px solid #ccc;
  border-top: none;
  max-height: 250px !important;
  overflow-y: auto;
  z-index: 9;
}

.custom-select .options li {
  padding: 13px 19px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  cursor: pointer;
  background-color: white;
}

/* .bordercircle {
  width: 14px;
  height: 14px;
  padding: 0px !important;
  border-radius: 122px;
  border: 2px solid #1E1E1E !important;
  background-color: #FF7C5C !important;
} */

.custom-select .options li {
  border-bottom: 1px solid rgba(68, 68, 68, 0.24);
  color: #1E1E1E;
  font-family: Inter Tight;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

.custom-select .options li:hover {
  background-color: #f0f0f0;
}

.option-price {
  display: flex;
  flex-direction: column;
  padding: 10px;
  text-align: center;
  border: 1px solid #ccc;
  border-radius: 8px;
  background: #f5f5f5;
}

.option-price .upper {
  display: flex;
  width: 70%;
  justify-content: space-between;
  align-items: center;
  margin: 10px auto;
}

.option-price .upper input {
  border-radius: 5px;
  padding: 5px 5px;
  width: 35px;
  border: 1px solid #ccc;
  text-align: center;
}

.option-price .lower {
  padding: 10px;
}

.istam {
  display: flex;
  justify-content: center;
  padding: 0px;
  margin: 0 auto;
}

.istam .submit {
  padding: 10px 20px !important;
  background-color: #ff7c5c !important;
  border-radius: 10px;
  width: -moz-fit-content;
  width: fit-content;
  color: white !important;
  border: none;
  margin: 10px auto;
}

.istam .submit:hover {
  background-color: #ee927a !important;
}

.istam .close {
  padding: 10px 20px !important;
  background-color: #006b8c !important;
  border-radius: 10px;
  width: -moz-fit-content;
  width: fit-content;
  color: white !important;
  border: none;
  margin: 10px auto;
}

.istam .close:hover {
  background-color: #0d9cc8 !important;
}

/*# sourceMappingURL=selec.css.map */