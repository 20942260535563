.header {
  display: flex;
  width: 100%;
  flex-direction: column;
  // max-width: 1512px;
  margin: 0 auto;
  justify-content: center;
  align-items: center;
  border-bottom: 1px solid rgba(192, 192, 192, 0.27);
  .upper {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin: 2% auto 0px auto;
    width: calc(100% - 140px);
    img {
      height: 55px;
      width: 135px;
      object-fit: contain;
    }
    h3 {
      font-size: 32px;
      margin: 0px;
    }
    .nav-container {
      display: flex;
      gap: 40px;
      align-items: center;
      margin: 0px;
      // width: 41%;
      align-items: center;
      justify-content: space-around;
      margin: 0px 0px 0px 30px;
      font-weight: 500;
      .button-nav {
        padding: 0px;
        .button-icon {
          width: 25px;
          display: flex;
          align-items: center;
          justify-content: center;
          height: 25px;
        }
      }
    }
    .shop-box {
      display: flex;
      align-items: center;
      justify-content: center;
      gap: 10px;
      h3 {
        font-size: 16px;
        margin: 0px;
      }
      .circle {
        background-color: #f0f0f0;
        width: 37px;
        border-radius: 222px;
        height: 37px;
      }
    }
    .button {
      .button-icon {
        margin: 4px 0px 0px 0px;
      }
    }
  }
  .lower-nav {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: calc(100% - 140px);
    margin: 45px auto 26px auto;

    flex-direction: row-reverse;
    .tags-container {
      display: flex;
      gap: 13px;
      align-items: center;
      button p span svg {
        height: 18px;
      }
    }
    h4 {
      margin: 0px;
    }
    .time {
      color: #444;
      font-family: "Inter Tight";
      font-size: 16px;
      font-style: normal;
      font-weight: 500;
      line-height: normal;
    }
  }
  .lower {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: calc(100% - 140px);
    margin: 19px auto 10px auto;
    .tags-container {
      display: flex;
      gap: 13px;
    }
    .time {
      color: #444;
      font-family: "Inter Tight";
      font-size: 16px;
      font-style: normal;
      font-weight: 500;
      align-items: center;
      display: flex;
      gap: 10px;
      span svg {
        height: 18px;
      }
      line-height: normal;
    }
  }
}
.button-nav {
  padding: 11px 20px;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.3s, color 0.3s;
}

// .button-nav:hover {
//   background-color:#FF7C5C !important;
//   color: white !important;
//   p span svg{
//     fill: white !important;
//   }
// }

////
@media screen and (max-width: 1024px) {
  .header {
    .upper {
      margin: 25px auto 0px auto;
      width: calc(100% - 90px);
      h3 {
        font-size: 28px;
      }
    }
    .lower {
      margin: 20px auto 16px auto;
      width: calc(100% - 90px);
      .time {
        font-size: 13px;
      }
    }
  }
}

.active-nav {
  padding: 11px 20px !important;
}
