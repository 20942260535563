.button {
  border: none;
  outline: none;
  border-radius: 6px;
  padding: 8px 19px;
  cursor: pointer;
}
.button .button-text {
  display: flex;
  gap: 12px;
  align-items: center;
  font-family: "Inter Tight";
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  margin: 0px;
}
.button .button-text .button-icon {
  margin: 0px;
}

.button-tag {
  border: none;
  outline: none;
  border-radius: 6px;
  padding: 10px 19px;
}
.button-tag .button-text {
  display: flex;
  gap: 12px;
  align-items: center;
  font-family: "Inter Tight";
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  margin: 0px;
}
.button-tag .button-text .button-icon {
  margin: 0px;
}

.button-medium {
  border: none;
  outline: none;
  border-radius: 6px;
  padding: 17px 29px;
}
.button-medium .button-text {
  display: flex;
  gap: 12px;
  align-items: center;
  font-family: "Inter Tight";
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  margin: 0px;
}
.button-medium .button-text .button-icon {
  margin: 0px;
}

.button-nav {
  border: none;
  outline: none;
  border-radius: 6px;
  padding: 11px 14px;
}
.button-nav .button-text {
  display: flex;
  gap: 12px;
  align-items: center;
  font-family: "Inter Tight";
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  margin: 0px;
}
.button-nav .button-text .button-icon {
  margin: 0px;
}

@media screen and (max-width: 1024px) {
  .button {
    border-radius: 6px;
    padding: 8px 19px;
  }
  .button .button-text {
    gap: 8px;
    font-size: 13px;
  }
  .button .button-text .button-icon {
    margin: 0px;
  }
  .button-tag {
    border-radius: 6px;
    padding: 7px 14px;
    height: 35px;
  }
  .button-tag .button-text {
    gap: 8px;
    font-size: 13px;
    margin: 0px 0px 3px 0px;
  }
  .button-tag .button-text .button-icon {
    margin: 0px;
    width: 19px;
    height: 19px;
  }
  .button-medium {
    border-radius: 6px;
    padding: 10px 15px;
  }
  .button-medium .button-text {
    gap: 8px;
    font-size: 13px;
  }
  .button-medium .button-text .button-icon {
    margin: 0px;
  }
}/*# sourceMappingURL=Button.css.map */