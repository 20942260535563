.price-check-container {
  display: flex;
  flex-direction: column;
  width: calc(100% - 140px);
  margin: 0 auto;
}
.price-check-container .price-check-row {
  display: flex;
  gap: 14px;
  margin: 20px 0px;
  flex-wrap: wrap;
}
.price-check-container .price-check-row .row-left {
  display: flex;
  gap: 14px;
  flex-basis: 65%;
}
.price-check-container .price-check-row .search-button {
  padding: 23px 52px 22px 53px;
  font-family: 'Inter Tight';
  border-radius: 10px;
  background: #006b8c;
  font-size: 16px;
  border: none;
  outline: none;
  box-shadow: 0px -1px 6px 0px rgba(0, 0, 0, 0.08) inset;
  color: white;
  margin: 4px;
}
.price-check-container .price-check-row .search-input {
  display: flex;
  align-items: center;
  border-radius: 10px;
  position: relative;
  flex: 1;
}
.price-check-container .price-check-row .search-input .input-field {
  flex: 1 1;
  background: #f5f5f5;
  box-shadow: 0px -1px 6px 0px rgba(0, 0, 0, 0.08) inset;
  padding: 23px 23px 23px 55px;
  border: none;
  font-size: 16px;
  border-radius: 10px;
  outline: none;
  color: #444;
  font-family: Inter Tight;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}
.price-check-container .price-check-row .search-input .search-icon {
  color: #777;
  margin-right: 5px;
  margin-left: 10px;
  position: absolute;
  font-size: 16px;
  top: 27px;
  font-weight: 100;
  left: 20px;
}
.price-check-container .price-check-table {
  width: 100%;
  min-height: 300px;
}
.price-check-container .price-check-table .button-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.price-check-container .price-check-table .button-container .nav-num {
  display: flex;
  align-items: center;
  gap: 5px;
}
.price-check-container .price-check-table .button-container .nav-num span {
  color: #d9d9d9;
  font-size: 30px;
  margin: 7px 0px 0px 0px;
}
.price-check-container .price-check-table .button-container .nav-num .active {
  background: #ff7c5c;
  opacity: 1 !important;
}
.price-check-container .price-check-table .button-container .nav-num .butto {
  border-radius: 44px;
  width: 35px;
  height: 35px;
  font-size: 16px;
  font-weight: 500;
  line-height: normal;
  border: none;
  color: white;
  outline: none;
  background: #ff7c5c;
  opacity: 0.3;
}
.price-check-container .price-check-table .button-container .navi {
  display: flex;
  gap: 20px;
}
.price-check-container .price-check-table .button-container .navi button.next {
  background: #006b8c;
  border-radius: 6px;
  padding: 17px 47px;
  color: white;
  font-family: 'Inter Tight';
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  border: none;
  outline: none;
}
.price-check-container .price-check-table .button-container .navi button.prev {
  background: #ff7c5c;
  border-radius: 6px;
  padding: 17px 38px;
  color: white;
  font-family: 'Inter Tight';
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  border: none;
  outline: none;
}
.price-check-container .price-check-table .no-data {
  display: flex;
  height: 200px;
  align-items: center;
  justify-content: center;
  color: #cd5050;
  text-align: center;
  font-family: 'Inter Tight';
  font-size: 24px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
}
.price-check-container .price-check-table table.blueTable {
  width: 100%;
  text-align: center;
  border-collapse: collapse;
  font-family: 'Inter Tight', sans-serif;
  overflow: hidden;
  border-radius: 13px;
  border: 1px solid rgba(68, 68, 68, 0.08);
  background: rgba(255, 255, 255, 0.31);
}
.price-check-container .price-check-table table.blueTable th {
  padding: 25px 21px 20px 21px;
}
.price-check-container .price-check-table table.blueTable td {
  padding: 19px 0px;
}
.price-check-container .price-check-table table.blueTable .fixed {
  width: 143px;
  padding: 0px;
}
.price-check-container .price-check-table table.blueTable .longer {
  width: 25%;
  padding: 0px;
}
.price-check-container .price-check-table table.blueTable tbody td {
  font-size: 16px;
  color: #000;
  text-align: center;
  font-family: Inter Tight;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}
.price-check-container .price-check-table table.blueTable tbody td img {
  width: 49px;
  border-radius: 9px;
  height: 49px;
}
.price-check-container .price-check-table table.blueTable thead {
  background: #ffcfc3;
}
.price-check-container .price-check-table table.blueTable thead th {
  color: #444;
  font-family: Inter Tight;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
}
.price-check-container
  .price-check-table
  table.blueTable
  tbody
  tr:nth-child(even) {
  background-color: #f5f5f5; /* Change this to your desired background color */
}
.price-check-container
  .price-check-table
  table.blueTable
  tbody
  tr:last-child
  td {
  border-bottom: none; /* Remove the border on the last row */
}
.price-check-container .buto {
  margin: 0 auto;
}
.price-check-container .buto button {
  border-radius: 9px;
  background: #ff7c5c;
  display: inline-flex;
  padding: 19px 48px;
  justify-content: center;
  outline: none;
  align-items: center;
  gap: 10px;
  color: #fff;
  text-align: center;
  border: none;
  margin: 59px auto;
  font-family: Inter Tight;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
} /*# sourceMappingURL=CheckPrice.css.map */
