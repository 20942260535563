.loginPage {
  background-color: #ff7c5c;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  .container {
    display: flex;
    flex-direction: column;
    max-width: 370px;
    justify-content: center;
    width: 100%;
    figure {
      // width: 208px;
      height: 82px;
      margin: 0 auto 20% auto;
      img {
        width: 100%;
        height: 100%;
        object-fit: contain;
      }
    }
    .remember-container {
      display: flex;
      justify-content: flex-start;
      margin: 19px 0px 31px 0px;
      .remember {
        display: flex;
        align-items: center;
        color: white;
        .custom-checkbox {
          .checkmark {
            width: 28px;
            height: 28px;
            border: 1px solid white;
          }
          .checkmark::before {
            left: 12%;
          }
          p {
            color: #fff;
            font-family: Inter Tight;
            font-size: 16px;
            margin: 0px 0px 0px 10px;
            font-style: normal;
            font-weight: 400;
            line-height: normal;
          }
        }
        p {
          margin: 0px;
        }
        input {
          margin-right: 10px;
          width: 23px;
          height: 23px;
          outline: none;
          border: none;
        }
      }
      .forgot {
        color: #fff;
        font-family: "Inter Tight";
        font-size: 16px;
        font-style: normal;
        font-weight: 500;
        line-height: normal;
        text-decoration-line: underline;
        margin: 4px;
      }
    }
    /* Error message styles */
    .error-message {
      color: #fff;
      font-size: 14px;
      font-weight: 500;
      margin-top: 5px;
      margin-bottom: 5px;
    }

    a {
      display: flex;
      text-decoration: none;
      button {
        border: none;
        outline: none;
        width: 100%;
        border-radius: 10px;
        background: #006b8c;
        box-shadow: 0px -1px 6px 0px rgba(0, 0, 0, 0.08) inset;
        padding: 23px;
        color: #fff;
        font-family: "Inter Tight";
        font-size: 16px;
        margin-top: 10px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
      }
    }
  }
}
