/* Spinner styles */
.spinner-container {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 70vh;
}

.spin-container {
  padding: 37px 50px;
  border-radius: 13px;
  background: #fff;
  box-shadow: 0px 16px 57px 0px rgba(0, 0, 0, 0.1);
  display: flex;
  gap: 14px;
  flex-direction: column;
}
.spin-container p {
  color: #FF7C5C;
  font-family: Inter Tight;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}

.spinner {
  border: 4px solid #ff7c5c; /* Border color for the spinner */
  border-top: 4px solid transparent; /* Transparent top border for animation */
  border-radius: 50%; /* Circular shape */
  width: 50px; /* Width and height of the spinner */
  height: 50px;
  animation: spin 1s linear infinite; /* Spin animation */
}

.spinner-containers {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  margin-bottom: 50px;
  height: 200px;
}

.spin-containers {
  border-radius: 13px;
}
.spin-containers p {
  color: #FF7C5C;
  font-family: Inter Tight;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}

.spinners {
  border: 2px solid #ff7c5c; /* Border color for the spinner */
  border-top: 2px solid transparent; /* Transparent top border for animation */
  border-radius: 50%; /* Circular shape */
  width: 20px; /* Width and height of the spinner */
  height: 20px;
  animation: spin 1s linear infinite; /* Spin animation */
}

/* Keyframes animation for the spinner */
@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
/* Spinner styles *//*# sourceMappingURL=Spinner.css.map */