/* Modal Overlay Styles */
.modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.24); /* Semi-transparent background */
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000; /* Ensure the modal is above other elements */
}

/* Modal Styles */
// .modal {
//   background-color: #fff;
//   padding: 20px;
//   border-radius: 5px;
//   box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
//   position: relative;
// }

/* Close Button Styles */
.close-button {
  position: absolute;
  top: 10px;
  right: 10px;
  background: none;
  border: none;
  font-size: 24px;
  cursor: pointer;
}

.modal {
  display: flex;
  flex-direction: column;
  padding: 0px 25px;
  width: 50%;
  background-color: #fff;
  max-width: 600px;
  border-radius: 8px;

  .Upper-modal-row {
    display: flex;
    justify-content: space-between;
    border-bottom: 1px solid #f5f5f5;
    align-items: center;
    h2 {
      color: #000;
      font-family: Inter Tight;
      margin: 0px;
      font-size: 20px;
      margin-top: 35px;
      margin-bottom: 32px;
      font-style: normal;
      font-weight: 500;
      line-height: normal;
    }
  }
  .rown {
    display: flex;
    justify-content: space-between;
    gap: 15px;
    margin: 20px 0px 20px 0px;
    .button-mediur {
      border: none;
      outline: none;
      padding: 22px 29px;
      border-radius: 10px;
      width: 280px;
      background: #006b8c;
      box-shadow: 0px -1px 6px 0px rgba(0, 0, 0, 0.08) inset;
      .button-text {
        display: flex;
        gap: 12px;
        align-items: center;
        font-family: 'Inter Tight';
        font-size: 16px;
        font-style: normal;
        font-weight: 500;
        line-height: normal;
        margin: 0px;
      }
    }
  }
  .input-boxer {
    display: flex;
    position: relative;
    .youu {
      border-radius: 10px;
      display: flex;
      padding: 23px;
      width: 274px;
      color: #444;
      gap: 11px;
      font-family: Inter Tight;
      border: none;
      outline: none;
      color: #1e1e1e;
      font-family: Inter Tight;
      font-size: 16px;
      font-style: normal;
      font-weight: 400;
      line-height: normal;
      font-size: 16px;
      font-style: normal;
      font-weight: 400;
      line-height: normal;
      background: #f5f5f5;
      box-shadow: 0px -1px 6px 0px rgba(0, 0, 0, 0.08) inset;
    }
    .custom-select {
      .options {
        width: 318px;
        li span {
          position: relative;
          top: 1px;
          right: 6px;
        }
      }
      .csi {
        border-radius: 10px;
        padding: 23px;
        width: 274px;
        color: #444;
        font-family: Inter Tight;
        border: none;
        outline: none;
        font-size: 16px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
        background: #f5f5f5;
        box-shadow: 0px -1px 6px 0px rgba(0, 0, 0, 0.08) inset;
      }
    }
    input,
    select {
      border-radius: 10px;
      padding: 23px;
      width: 100%;
      color: #444;
      font-family: Inter Tight;
      border: none;
      outline: none;
      font-size: 16px;
      font-style: normal;
      font-weight: 400;
      line-height: normal;
      background: #f5f5f5;
      box-shadow: 0px -1px 6px 0px rgba(0, 0, 0, 0.08) inset;
    }
    span {
      position: absolute;
      top: 23px;
      right: 20px;
    }
  }
}
.row {
  display: flex;
  justify-content: space-between;
  margin: 50px 0px 0px 0px;
  .button-mediur {
    border: none;
    outline: none;
    padding: 22px 29px;
    border-radius: 10px;
    background: #006b8c;
    box-shadow: 0px -1px 6px 0px rgba(0, 0, 0, 0.08) inset;
    .button-text {
      display: flex;
      gap: 12px;
      align-items: center;
      font-family: 'Inter Tight';
      font-size: 16px;
      font-style: normal;
      font-weight: 500;
      line-height: normal;
      margin: 0px;
      .button-icon {
        margin: 0px;
      }
    }
  }
  .input-boxer {
    display: flex;
    width: 49%;
    position: relative;

    input,
    select {
      border-radius: 10px;
      padding: 23px;
      width: 100%;
      color: #444;
      font-family: Inter Tight;
      border: none;
      outline: none;
      font-size: 16px;
      font-style: normal;
      font-weight: 400;
      line-height: normal;
      background: #f5f5f5;
      box-shadow: 0px -1px 6px 0px rgba(0, 0, 0, 0.08) inset;
    }
    span {
      position: absolute;
      top: 23px;
      right: 20px;
    }
  }
}
.columner {
  display: flex;
  flex-direction: column;
  .rows {
    display: flex;
    gap: 52px;
    margin-bottom: 44px;
    .checkbox-container {
      font-size: 16px;
      display: flex;
      gap: 14px;
      align-items: center;
      /* Define the container for the checkbox */
      .rounded-checkbox {
        display: inline-block;
        position: relative;
        width: 24px; /* Adjust the width and height to control the size of the checkbox */
        height: 24px;
      }

      /* Hide the default checkbox input */
      .rounded-checkbox input {
        display: none;
      }

      /* Style the custom checkbox appearance */
      .rounded-checkbox .checkmark {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background-color: #d9d9d9; /* Set the background color to transparent */
        border: 2px solid #fff; /* Add a border to create the circle */
        border-radius: 50%; /* Make it round */
      }

      /* Style the custom checkbox when it's checked */
      .rounded-checkbox input:checked + .checkmark {
        background-color: #d9d9d9;
        width: 12px;
        height: 12px;
        border: 6px solid #ff7c5c; /* Change the background color to your desired color when checked */
      }

      /* Style the custom checkbox when it's hovered */
      .rounded-checkbox:hover .checkmark {
        border-color: #888; /* Change the border color when hovered */
      }

      input {
        border-radius: 50%;
      }
    }
  }
  textarea {
    display: flex;
    min-height: 101px;
    border-radius: 10px;
    border: 1px solid rgba(68, 68, 68, 0.4);
    box-shadow: 0px -1px 6px 0px rgba(0, 0, 0, 0.08) inset;
    color: #444;
    font-family: Inter Tight;
    font-size: 16px;
    font-style: normal;
    padding: 23px;
    font-weight: 400;
    line-height: normal;
    margin-bottom: 37px;
  }
  .modal-button {
    margin: 75px auto 47px auto;
  }
  button {
    display: inline-flex;
    padding: 18px 68px 18px 61px;
    justify-content: center;
    align-items: center;
    gap: 12px;
    color: white;
    border-radius: 6px;
    color: #fff;
    font-family: Inter Tight;
    width: fit-content;
    border: none;
    .button-text {
      display: flex;
      gap: 12px;
      align-items: center;
      font-family: 'Inter Tight';
      font-size: 16px;
      font-style: normal;
      font-weight: 500;
      line-height: normal;
      margin: 0px;
      .button-icon {
        margin: 0px;
      }
    }
    outline: none;
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    align-self: center;
    line-height: normal;
    background: #ff7c5c;
  }
}
.columneri {
  display: flex;
  flex-direction: column;
  .rows {
    display: flex;
    gap: 52px;
    margin-bottom: 44px;
    .custom-checkbox {
      .checkmark {
        background-color: #e3e3e3;
      }
      input:checked + .checkmark {
        background-color: #ff7c5c;
      }
    }
  }
  textarea {
    display: flex;
    min-height: 101px;
    border-radius: 10px;
    border: 1px solid rgba(68, 68, 68, 0.4);
    box-shadow: 0px -1px 6px 0px rgba(0, 0, 0, 0.08) inset;
    color: #444;
    font-family: Inter Tight;
    font-size: 16px;
    font-style: normal;
    padding: 23px;
    font-weight: 400;
    line-height: normal;
  }
  .modal-button {
    margin: 37px auto 31px auto;
  }
  button {
    display: inline-flex;
    padding: 18px 68px 18px 61px;
    justify-content: center;
    align-items: center;
    gap: 12px;
    color: white;
    border-radius: 6px;
    color: #fff;
    font-family: Inter Tight;
    width: fit-content;
    border: none;
    .button-text {
      display: flex;
      gap: 12px;
      align-items: center;
      font-family: 'Inter Tight';
      font-size: 16px;
      font-style: normal;
      font-weight: 500;
      line-height: normal;
      margin: 0px;
      .button-icon {
        margin: 0px;
      }
    }
    outline: none;
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    align-self: center;
    line-height: normal;
    background: #ff7c5c;
  }
}
.row-modal-eir {
  .custom-select {
    .options {
      height: 179px;
    }
    .csi {
      width: 90%;
    }
  }
}
.reciept-container {
  display: flex;
  justify-content: center;
  width: 100%;
  max-height: 80vh;
  overflow-y: scroll;
  align-items: center;
  .reciept {
    display: flex;
    width: 350px;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    font-size: 12px;
    //  padding: 0 0px 0px 19px;
    // margin: 0px 0px 0px -10px;
    h1 {
      font-size: 34px;
      margin-top: 0px;
    }
    h2 {
      display: flex;

      align-items: center;
      font-size: 24px;
      gap: 20px;
      margin: 10px;
    }
    p {
      margin-top: 0;
      margin-bottom: 3px;
    }
    .reciept-row {
      display: flex;
      margin-bottom: 9px;
      justify-content: space-between;
      p {
        width: 40%;
      }
      width: 100%;
      max-width: 350px;
      p {
        margin: 0;
      }
    }
    .reow-reciept {
      display: flex;
      // justify-content: space-between;
      width: 100%;
      margin-bottom: 4px;

      max-width: 350px;
      // align-items: center;
      // justify-content: center;
      margin: 0px 0px 10px 0px;

      .quantity {
        width: 80px;
        text-align: left;
        margin-bottom: 0;
        margin-right: 10px;
        margin-top: 0;
      }
      .product {
        width: 85px;
        margin-bottom: 0;
        margin-top: 0;
      }
      .price {
        width: 90px;
        margin-bottom: 0;
        margin-top: 0;
      }
      p {
        margin: 0;
      }
    }
    .reow-recieptsss {
      display: flex;
      justify-content: flex-end;
      gap: 30px;
      width: 60%;
      margin-bottom: 4px;
      p {
        margin: 0;
      }
    }
  }
}

@media print {
  .receipt {
    display: block;
    width: auto;
    padding: 0;
    margin: 0;

    .reciept h1 {
      font-size: 34px;
      margin-top: 0px;

      // margin: 200px 0px 0px 0px;
      // padding: 200px 0px 0px 0px;
    }
    h2 {
      display: flex;

      align-items: center;
      font-size: 24px;
      gap: 20px;
      margin: 10px;
    }
    p {
      margin-top: 0;
      margin-bottom: 3px;
    }
    .reciept-row {
      display: flex;
      margin-bottom: 9px;
      justify-content: space-between;
      p {
        width: 33%;
      }
      width: auto;
      p {
        margin: 0;
      }
    }
    .reow-reciept {
      display: flex;
      // justify-content: space-between;
      width: auto;
      margin-bottom: 4px;
      // align-items: center;
      // justify-content: center;
      margin: 0px 0px 10px 0px;

      .quantity {
        width: 115px;
        text-align: left;
        margin-bottom: 0;
        margin-right: 10px;
        margin-top: 0;
      }
      .product {
        width: 135px;
        margin-bottom: 0;
        margin-top: 0;
      }
      .price {
        width: 90px;
        margin-bottom: 0;
        margin-top: 0;
      }
      p {
        margin: 0;
      }
    }
    .reow-recieptsss {
      display: flex;
      justify-content: flex-end;
      gap: 30px;
      width: 60%;
      margin-bottom: 4px;
      p {
        margin: 0;
      }
    }
  }
}
