.repair-sheet-container {
    display: flex;
    flex-direction: column;
    width: calc(100% - 140px);
    // max-width:1372px ;
    margin: 0 auto;
    .buttons-tab-container {
      display: flex;
      gap: 8px;
      padding: 8px;
      margin: 31px 0px 15px 0px;
      border-radius: 9px;
      width: fit-content;
      button {
        padding: 15px 35px;
        border-radius: 6px;
        font-size: 16px;
        border: none;
        outline: none;
      }
    }
    .inputs-container {
      display: flex;
      flex-direction: column;
      border-radius: 13px;
      background: #f5f5f5;
      gap: 25px;
      padding: 36px 0px;
      margin: 0px 0px 20px 0px;
      .inputs-container-upper {
        display: flex;
        padding: 0px 48px;
        flex-wrap: wrap;
        justify-content: space-between;
      }
    }
    .inputs-container-bottom {
      display: flex;
      justify-content: space-between;
      border-top: 1px solid rgba(0, 0, 0, 0.24);
      padding: 36px 48px;
    
    }
    .balance-container {
      display: flex;
      gap: 27px;
      align-items: center;
      p {
        color: #000;
        font-family: Inter Tight;
        font-size: 20px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
      }
    }
    .bottom-sheet-container {
      display: flex;
      gap: 42px;
      margin: 10px 0px 60px 0px ;
      align-items: center;
      padding: 35px 46px;
      background-color: #F5F5F5;
      border-radius:13px ;
    p{
        color: #444;
font-family: Inter Tight;
font-size: 16px;
font-style: normal;
font-weight: 400;
    }
    .submit{
      padding: 16px 53px;
      background-color: #ff7c5c;
      border-radius: 10px;
      width: fit-content;
      color: white;
      border: none;
      margin: 0 auto;
    }
    }
    .bottom-sheet-containers {
      display: flex;
      gap: 42px;flex-direction: column;
      margin: 0px  ;
      .sheet-right {
        display: flex;
        flex-direction: column;
        width: 100%;
        h2 {
          text-align: left;
          color: #444;
          font-family: Inter Tight;
          font-size: 24px;
          font-style: normal;
          margin: 0;
          font-weight: 500;
          line-height: normal;
          // margin: 22px auto 126px auto;
        }
        ul{
          margin: 0;
          li{
            p{
              margin-bottom: 10px;
              margin-top: 0px;
            }
          }
          padding: 0;
        }
        .save {
          padding: 18px 68px 18px 61px;
          border: none;
          display: flex;
          align-items: center;
          justify-content: center;
          align-items: center;
          width: fit-content;

          gap: 12px;
          border-radius: 6px;
          color: white;
          font-family: Inter Tight;
          font-size: 16px;
          font-style: normal;
          font-weight: 500;
          line-height: normal;
          background: #ff7c5c;
          margin-bottom: 39px;
          align-self: center;
        }
        .checkbox-condition {
          padding: 22px 19px;
          margin: 20px 0px;
          border-radius: 8px;
          border: 1px solid rgba(0, 0, 0, 0.29);
          .custom-checkbox p {
            color: #000;
            font-family: Inter Tight;
            font-size: 16px;
            margin: 0px 0px 0px 16px;
            width: 100%;
            
            font-style: normal;
            font-weight: 400;
            line-height: normal;
          }
          
          /* Define the custom checkbox shape and default background */
          .custom-checkbox .checkmark {
            width: 19px;
            height: 19px;
            background-color: white; /* Default background color when unchecked */
            border: 1px solid #000; /* Border color for the square checkbox */
            border-radius: 6px;
            display: inline-block;
            vertical-align: middle;
            position: relative;
          }
  
          /* Style the checkmark when the checkbox is checked */
          .custom-checkbox input:checked + .checkmark {
            border: none;
            background-color: #ff7c5c; /* Background color when checked */
          }
  
          /* Style the checkmark itself */
          .custom-checkbox input:checked + .checkmark::before {
            content: "";
            position: absolute;
            top: 30%;
            left: 20%;
            transform: translate(-50%, -50%);
            width: 10px;
            height: 4px;
            border: 1px solid white; /* Color of the tick */
            border-top: none;
            border-right: none;
            transform: rotate(-45deg); /* Rotate the tick to form an X */
          }
        }
        .checkbox-conditions {
          padding: 22px 19px;
          margin: 20px 0px;
          border-radius: 8px;
          display: flex;
          flex-direction: column;
          gap: 20px;
          .custom-checkbox p {
            color: #000;
            font-family: Inter Tight;
            font-size: 16px;
            margin: 0px 0px 0px 16px;
            width: 100%;
            font-style: normal;
            font-weight: 400;
            line-height: normal;
          }
          
          /* Define the custom checkbox shape and default background */
          .custom-checkbox .checkmark {
            width: 19px;
            height: 19px;
            background-color: white; /* Default background color when unchecked */
            border: 1px solid #000; /* Border color for the square checkbox */
            border-radius: 6px;
            display: inline-block;
            vertical-align: middle;
            position: relative;
          }
  
          /* Style the checkmark when the checkbox is checked */
          .custom-checkbox input:checked + .checkmark {
            border: none;
            background-color: #ff7c5c; /* Background color when checked */
          }
  
          /* Style the checkmark itself */
          .custom-checkbox input:checked + .checkmark::before {
            content: "";
            position: absolute;
            top: 30%;
            left: 20%;
            transform: translate(-50%, -50%);
            width: 10px;
            height: 4px;
            border: 1px solid white; /* Color of the tick */
            border-top: none;
            border-right: none;
            transform: rotate(-45deg); /* Rotate the tick to form an X */
          }
        }
        h3 {
          color: #444;
          font-family: Inter Tight;
          font-size: 16px;
          font-style: normal;
          font-weight: 600;
          line-height: normal;
        }
        p {
          color: #444;
          font-family: Inter Tight;
          margin: 10px 0px 10px 0px;
          font-size: 16px;
          font-style: normal;
          font-weight: 400;
          line-height: 26px; /* 0px */
        }
      }
      .checkbox-conditions{
        display: flex;
        flex-direction: column;
        width: 100%;
        gap: 20px;
      }
      align-items: center;
      padding: 15px 26px;
      background-color: #F5F5F5;
      border-radius:13px ;
    p{
        color: #444;
font-family: Inter Tight;
font-size: 16px;
font-style: normal;
font-weight: 400;
    }
    .submit{
      padding: 16px 53px;
      background-color: #ff7c5c;
      border-radius: 10px;
      width: fit-content;
      color: white;
      border: none;
      margin: 0 auto;
    }
    }
  }
  .column-container {
    display: flex;
    justify-content: space-between;
    flex-direction: column;
    gap: 34px;
    width: 28%;
   
    .input-box {
      display: flex;
      flex-direction: column;
      gap: 12px;
      width: 100%;
      label {
        color: #444;
        font-family: Inter Tight;
        font-size: 16px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
      }
      input,
      textarea {
        outline: none;
        border-radius: 10px;
        border: none;
        background: #fff;
        box-shadow: 0px -1px 6px 0px rgba(0, 0, 0, 0.08) inset;
        padding: 22px 0px 23px 26px;
        color:black;
        font-family: Inter Tight;
        font-size: 16px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
      }
    }
  }
  // input
  .input-box {
    display: flex;
    flex-direction: column;
    gap: 12px;
    width: 28%;
    position: relative;
    label {
      color: #444;
      font-family: Inter Tight;
      font-size: 16px;
      font-style: normal;
      font-weight: 400;
      line-height: normal;
    }
    .icon{
        position: absolute;
        right: 25px;
        top: 52px;
        font-size: 24px;
    }
    input,
    textarea {
      outline: none;
      border-radius: 10px;
      border: none;
      background: #fff;
      box-shadow: 0px -1px 6px 0px rgba(0, 0, 0, 0.08) inset;
      padding: 22px 0px 23px 26px;
      color: black;
      font-family: Inter Tight;
      font-size: 16px;
      font-style: normal;
      font-weight: 400;
      line-height: normal;
    }
  }
  .input-foam {
    outline: none;
    border-radius: 10px;
    border: none;
    max-width: 360px;
    background: #fff;
    box-shadow: 0px -1px 6px 0px rgba(0, 0, 0, 0.08) inset;
    padding: 22px 0px 23px 26px;
    color: black;
    font-family: Inter Tight;
    width: 30%;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    border: 1px solid rgba(0, 0, 0, 0.26);
  }
  /// checkbox
  ///
  /* Styles for the custom checkbox */
  /* Styles for the custom checkbox */
  .custom-checkbox {
    display: flex;
    align-items: center;
    position: relative;
  }
  
  /* Hide the default checkbox input */
  .custom-checkbox input {
    position: absolute;
    opacity: 0;
    cursor: pointer;
  }
  .custom-checkbox p {
    color: #000;
    font-family: Inter Tight;
    font-size: 20px;
    margin: 0px 0px 0px 16px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
  }
  /* Define the custom checkbox shape and default background */
  .custom-checkbox .checkmark {
    width: 34px;
    height: 34px;
    background-color: white; /* Default background color when unchecked */
    // border: 2px solid #FF7C5C; /* Border color for the square checkbox */
    border-radius: 6px;
    display: inline-block;
    vertical-align: middle;
    position: relative;
  }
  
  /* Style the checkmark when the checkbox is checked */
  .custom-checkbox input:checked + .checkmark {
    background-color: #ff7c5c; /* Background color when checked */
  }
  
  /* Style the checkmark itself */
  .custom-checkbox input:checked + .checkmark::before {
    content: "";
    position: absolute;
    top: 30%;
    left: 20%;
    transform: translate(-50%, -50%);
    width: 20px;
    height: 7px;
    border: 2px solid white; /* Color of the tick */
    border-top: none;
    border-right: none;
    transform: rotate(-45deg); /* Rotate the tick to form an X */
  }
  
  /* Optional: Add space between the checkbox and text */
  .custom-checkbox label {
    margin-left: 10px;
  }
  .css-o9k5xi-MuiInputBase-root-MuiOutlinedInput-root{
    background-color: white;
    // margin: 0px 0px 0px -46px;
    padding: 0;
    width: 100%;
    align-self: flex-end;
  }
  .custom-file-upload {
    border: none;
    display: inline-block;
    padding: 14px 30px;
    color: white !important;
    cursor: pointer;
    background-color: rgb(0, 107, 140);
    width: 121px;
    border-radius: 6px;
  }
  .css-1d3z3hw-MuiOutlinedInput-notchedOutline{
    border: none !important;
  }
  .css-z3c6am-MuiFormControl-root-MuiTextField-root{
    width: 100%;
  }
  /* Style the label when a file is selected */
  .custom-file-upload::after {
    content: 'Upload File'; /* Change this text as needed */
    margin-left: 15px;
  }
  
  /* Style the label when no file is selected */
  .custom-file-upload:hover::after {
    content: 'Upload File'; /* Change this text as needed */
  }
  
  /* Style the input button when selected */
  #file-upload:focus + .custom-file-upload {
    /* Add focused styles if needed */
  }
  .ester{
    flex-direction: row;
    display: flex;
    background-color: white;
    padding: 5px 3px;
    border-radius: 6px;
    gap: 0px;

    height: fit-content;
    .ester-er{
      width: 50%;
      display: flex;
      align-items: center;
      color: #444;
font-family: Inter Tight;
padding: 16px 0px;
background: #fff; 
text-align: center;
justify-content: center;
font-size: 13px;
font-style: normal;
font-weight: 400;
line-height: normal;
    }
  }
  .stock-page-container {
    .stocks-row {
      .css-o9k5xi-MuiInputBase-root-MuiOutlinedInput-root{
        background-color: #f5f5f5 !important;
        // margin: 0px 0px 0px -46px;
        padding: 0;
        width: 100%;
        align-self: flex-end;
        .css-1laqsz7-MuiInputAdornment-root{
          margin-right:10px ;
        }
      }
      
    }
  }