.price-check-container {
  display: flex;
  flex-direction: column;
  width: calc(100% - 140px);
  // max-width:1372px ;
  margin: 0 auto;
  .price-check-row {
    display: flex;
    gap: 14px;
    margin: 20px 0px;
    .row-left {
      display: flex;
      gap: 14px;
      width: 65%;
    }
    .search-button {
      padding: 23px 52px 22px 53px;
      font-family: 'Inter Tight';
      border-radius: 10px;
      background: #006b8c;
      font-size: 16px;
      border: none;
      outline: none;
      box-shadow: 0px -1px 6px 0px rgba(0, 0, 0, 0.08) inset;
      color: white;
      margin: 4px;
    }
    .search-input {
      display: flex;
      align-items: center;
      border-radius: 10px;
      position: relative;
      flex: 1;
      .input-field {
        flex: 1 1;
        background: #f5f5f5;
        box-shadow: 0px -1px 6px 0px rgba(0, 0, 0, 0.08) inset;
        padding: 23px 23px 23px 55px;
        border: none;
        font-size: 16px;
        border-radius: 10px;
        outline: none;
        color: #444;
        font-family: Inter Tight;
        font-size: 16px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
      }

      .search-icon {
        color: #777;
        margin-right: 5px;
        margin-left: 10px;
        position: absolute;
        font-size: 16px;
        top: 27px;
        font-weight: 100;
        left: 20px;
      }
    }
  }
  .price-check-table {
    width: 100%;
    min-height: 300px;
    .button-container {
      display: flex;
      justify-content: space-between;
      align-items: center;
      .nav-num {
        display: flex;
        align-items: center;
        gap: 5px;
        span {
          color: #d9d9d9;
          font-size: 30px;
          margin: 7px 0px 0px 0px;
        }
        .active {
          background: #ff7c5c;
          opacity: 1 !important;
        }
        .butto {
          border-radius: 44px;
          width: 35px;
          height: 35px;
          font-size: 16px;
          font-weight: 500;
          line-height: normal;
          border: none;
          color: white;
          outline: none;
          background: #ff7c5c;
          opacity: 0.3;
        }
      }
      .navi {
        display: flex;
        gap: 20px;
        button.next {
          background: #006b8c;
          border-radius: 6px;
          padding: 17px 47px;
          color: white;
          font-family: "Inter Tight";
          font-size: 16px;
          font-style: normal;
          font-weight: 500;
          line-height: normal;
          border: none;
          outline: none;
        }
        button.prev {
          background: #ff7c5c;
          border-radius: 6px;
          padding: 17px 38px;
          color: white;
          font-family: "Inter Tight";
          font-size: 16px;
          font-style: normal;
          font-weight: 500;
          line-height: normal;
          border: none;
          outline: none;
        }
      }
    }
    .no-data {
      display: flex;
      height: 200px;
      align-items: center;
      justify-content: center;
      color: #cd5050;
      text-align: center;
      font-family: "Inter Tight";
      font-size: 24px;
      font-style: normal;
      font-weight: 600;
      line-height: normal;
    }
    table.blueTable {
      width: 100%;
      text-align: center;
      border-collapse: collapse;
      font-family: "Inter Tight", sans-serif;
      overflow: hidden;
      border-radius: 13px;
      border: 1px solid rgba(68, 68, 68, 0.08);
      background: rgba(255, 255, 255, 0.31);
    }

   
    table.blueTable th {
      padding: 25px 21px 20px 21px;

      //   border-bottom: 1px solid #ccc; /* Add a 1px solid border at the bottom of each cell */
    }
    table.blueTable td{
        padding: 19px 0px;
    }
   table.blueTable .fixed{
    width: 143px;
    padding: 0px;
   }
   table.blueTable .longer{
    width: 25%;
    padding: 0px;
   }
    table.blueTable tbody td {
      font-size: 16px;
      color: #000;
      text-align: center;
      font-family: Inter Tight;
      font-size: 16px;
      font-style: normal;
      font-weight: 400;
      line-height: normal;
    }
    table.blueTable tbody td img {
      width: 49px;
      border-radius: 9px;
      height: 49px;
    }
    table.blueTable thead {
      background: #ffcfc3;
    }

    table.blueTable thead th {
      color: #444;
      font-family: Inter Tight;
      font-size: 16px;
      font-style: normal;
      font-weight: 600;
      line-height: normal;
    }
    table.blueTable tbody tr:nth-child(even) {
      background-color: #f5f5f5; /* Change this to your desired background color */
    }
    table.blueTable tbody tr:last-child td {
      border-bottom: none; /* Remove the border on the last row */
    }
  }
  .buto {
    margin: 0 auto;
    
    button{
    border-radius: 9px;
background: #FF7C5C;
display: inline-flex;
padding: 19px 48px;
justify-content: center;
outline: none;
align-items: center;
gap: 10px;
color: #FFF;
text-align: center;
border: none;
margin: 59px auto;
font-family: Inter Tight;
font-size: 16px;
font-style: normal;
font-weight: 500;
line-height: normal;
  }}
}
