.unl-container {
    margin: 0 auto;
    padding-block: 2rem;
    width: calc(100% - 140px);
}

.unl-content-block {
    background: #F5F5F5;
    width: 100%;
    padding: 20px 30px 30px;
    border-radius: 10px;
}

.unl-req-form {
    display: flex;
    align-items: center;
    flex-direction: row;
    gap: 15px;
    padding: 15px 20px;

}

.unl-content-block>* {
    width: auto;
    margin-block: 0;
}

.unl-content-block>.unl-header {
    display: flex;
    align-items: center;
    flex-direction: row;
    gap: 15px;
    padding: 15px 20px;
    border-bottom: 1px solid #C6C6C6;

}

.unl-content-block .unl-content {
    padding: 15px 20px;
    background: #f5f5f5;
}

.unl-content .custom-select {
    width: 100% !important;
}